<template>
  <div class="project-list__item fade"
       :class="['small', {mainVideo: mainVideo, margin: margin}]"
       @click="openDialog()"
  >
    <div class="img-block">
      <svg width="47" height="33" viewBox="0 0 47 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M46.9892 11.6302C47.0948 8.5767 46.4265 5.54602 45.0463 2.81959C44.1099
      1.70086 42.8103 0.945904 41.3741 0.686256C35.4335 0.147645 29.4682 -0.0731147 23.504
      0.0249243C17.5615 -0.0775644 11.6177 0.136071 5.69797 0.664924C4.52759 0.877652 3.44449
      1.42618 2.58084 2.24359C0.659327 4.01425 0.445825 7.04358 0.232324 9.60357C-0.0774413
      14.2064 -0.0774413 18.8248 0.232324 23.4275C0.29409 24.8684 0.508795 26.2986 0.872828
      27.6942C1.13026 28.7717 1.65109 29.7685 2.38869 30.5955C3.25821 31.4562 4.36654 32.036
      5.56986 32.2595C10.1728 32.8272 14.8107 33.0625 19.4475 32.9635C26.92 33.0702 33.4745
      32.9635 41.2246 32.3662C42.4575 32.1564 43.597 31.5759 44.4912 30.7022C45.0889 30.1047
      45.5354 29.3733 45.7936 28.5689C46.5571 26.2278 46.9321 23.7776 46.9038 21.3156C46.9892
      20.1209 46.9892 12.9102 46.9892 11.6302ZM18.6789 22.5955V9.39024L31.3182 16.0249C27.774
      17.9876 23.0983 20.2062 18.6789 22.5955Z" fill="#FF0000"/>
      </svg>

      <v-img :src="`${ApiUrl}v1/images${project.preview_url}`" class="item__img"
             :class="'small__img'" alt="preview"
      />
    </div>
    <p class="item__title">{{project.title}}</p>

    <IFrameDialog :url="project.url" ref="dialog"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {ApiUrl} from "@/global";
import IFrameDialog from "./IFrameDialog.vue"

export default {
  name: 'MediaProjectItem',
  components: {IFrameDialog},
  data() {
    return {
      dialog: false,
      video: false,
      ApiUrl
    }
  },
  comments: {IFrameDialog},

  computed: {
    ...mapState(['appWidth']),
  },

  methods: {
    openDialog() {
      this.$refs.dialog.openDialog()
    },
  },

  props: {
    project: {
      type: Object,
    },
    margin: {
      type: Boolean,
      default: false,
    },
    mainVideo: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/variables";
.img-block {
  position: relative;
  svg {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 45%;
    transform: translate(-50%);
  }
}

</style>
