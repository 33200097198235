<template>
  <v-dialog
    v-model="dialog"
    width="735px"
    @click:outside="closeDialog()"
  >
    <v-card class="modal">
      <v-card-actions class="close-btn">
        <button @click="closeDialog()">
          <v-icon class="close-icon">mdi-close</v-icon>
        </button>
      </v-card-actions>
      <div class="iframe-container">
        <iframe :width="appWidth > 1100 ? '560': '260'"
                :height="appWidth > 1100 ? '315' : '200'"
                class="iframe-video"
                :src="`https://www.youtube.com/embed/${url}?enablejsapi=1`"
                title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen v-if="video"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "IFrameDialog",

  props: {
    url: { type: String }
  },

  data() {
    return {
      dialog: false,
      video: false
    }
  },

  methods: {
    openDialog() {
      this.dialog = true
      setTimeout(() => this.video = true, 200)
    },
    closeDialog() {
      this.dialog = false
      let videos = document.getElementsByClassName('iframe-video')
      for (const video of videos) {
        video.contentWindow.postMessage(JSON.stringify({ event: 'command',
          func: 'stopVideo' }), '*')
      }
    }
  },
  computed: {
    ...mapState(['appWidth']),
  },
}
</script>

<style scoped lang="scss">

.modal {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
  height: 100%;
}

.iframe-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 700px;

  .iframe-video {
    width: 95%;
    min-height: 400px;
  }
}


.close-icon{
  color: white;
}

.close-btn{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 0 10px 0 !important;
}

@media (max-width: 600px) {
  .modal{
    justify-content: space-between;
    height: 90vh;
  }
  .close-btn{
    padding-right: 20px !important;
  }
  .iframe-container {
    flex-grow: 1;
    iframe{
      width: 100% !important;
      min-height: 210px !important;
    }
  }
}
</style>
