<template>
  <section class="projects fade">
    <div class="inline-grid-container container-padding">
      <LandingTitleBlock
        :title="$t('mediaProjects')" :filters="filterTypes" isFilter isChannel
        @changeActiveTab="changeActiveTab"
      />
    </div>
    <Loader v-if="loading" />
    <div class="media-list inline-grid-container container-padding">
      <MediaProjectItem v-for="el in filteredVideo" :key="el.id" :project="el"/>
    </div>

    <button
      type="button"
      class="yellow-btn"
      v-if="!!videos.next"
      @click="getMoreVideos"
    >
      {{$t('showMore')}}
    </button>
  </section>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import LandingTitleBlock from '../../components/LandingTitleBlock.vue';
import MediaProjectItem from '../../components/MediaProjectItem.vue';
import Loader from '../../components/UI/Loader.vue';

export default {
  name: 'MediaProjects',

  data() {
    return {
      loading: true,
      activeTab: null,
      page: 1,
    }
  },

  computed: {
    ...mapState('mediaProjects', ['videos']),
    ...mapState('mediaProjects', ['videoTypes']),
    filteredVideo() {
      if (this.activeTab === 0) {
        return this.videos.results
      }
      return this.videos.results.filter(el => +el.project_type === +this.activeTab)
    },
    filterTypes() {
      return [{ id: 0, type: this.$t('allVideos') }].concat(this.videoTypes)
    },
  },

  methods: {
    ...mapActions('mediaProjects', ['getVideos', 'getVideoTypes']),
    ...mapMutations('mediaProjects', ['resetVideos']),
    async getMoreVideos() {
      this.page++
      await this.getVideos({page: this.page, pageSize: 12, typeId: this.activeTab})
    },
    async changeActiveTab(el) {
      this.loading = true
      this.resetVideos()
      this.page = 1
      this.activeTab = el
      await this.getVideos({page: this.page, pageSize: 12, typeId: el})
      this.loading = false
    },
  },

  async mounted() {
    this.resetVideos()
    await this.getVideos({page: this.page, pageSize: 12})
    await this.getVideoTypes()
    this.activeTab = this.filterTypes[0].id
    this.loading = false
    console.log(this.filterTypes)
  },

  components: { LandingTitleBlock, MediaProjectItem, Loader },
};
</script>

<style scoped lang="scss">

.inline-grid-container{
  width: 100%;
}
 .media-list.inline-grid-container {
   grid-template-columns: repeat(4, minmax(0, 340px));
   width: 100%;
 }


@media (max-width: 390px) {
  .media-list.inline-grid-container {
    grid-template-columns: repeat(1, minmax(0px, 340px))
  }
}
 @media (min-width: 791px) and (max-width: 1210px) {
   .media-list.inline-grid-container {
     grid-template-columns: repeat(3, minmax(0px, 340px));
   }
 }

 @media (min-width: 391px) and (max-width: 790px) {
   .media-list.inline-grid-container {
     grid-template-columns: repeat(2, minmax(0px, 340px));
   }
 }

</style>
